import React from 'react'

const ServiceTitle = ({
  index,
  title,
  handleTitleOnEnter,
  handleTitleOnExit,
  color,
  current,
}) => {
  const handleIn = event => {
    handleTitleOnEnter(index)
  }

  const handleOut = event => {
    handleTitleOnExit(index)
  }

  const styledColor = current === index ? color : "#000000"

  return (
    <>
      <h2
        onMouseEnter={handleIn}
        onMouseLeave={handleOut}
        style={{ color: styledColor }}
      >
        {title}
      </h2>
    </>
  )
}

export default ServiceTitle
