import React, { useState } from 'react'
import Plus from '../../assets/svg/plus.svg'

const ServiceCard = ({ title, description, color }) => {
  const copyContainerRef = React.useRef(),
        copyHeightRef = React.useRef(),
        titleRef = React.useRef(),
        [showDescription, setShowDescription] = useState(false)

  const openAccordion = event => {
    if (!showDescription) {
      copyContainerRef.current.style.maxHeight =
        copyHeightRef.current.scrollHeight + "px"
    } else {
      copyContainerRef.current.style.maxHeight = "0px"
    }
  }

  const toggleClasses = event => {
    setShowDescription(!showDescription)
    openAccordion()
  }

  const btnClass = showDescription ? "opened" : "closed"

  return (
    <>
      <div className="service-card">
        <div className="service-card__inner">
          <div
            className={`service-card__header ${btnClass}`}
            onClick={toggleClasses}
          >
            <h2 style={{ color: showDescription ? color : "#000000" }}>
              {title}
            </h2>
            <Plus />
          </div>
          <div className="service-card__copy">
            <div className="service-card__copy-inner" ref={copyContainerRef}>
              <div
                className="service-card__copy-wrapper"
                ref={copyHeightRef}
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceCard
