import React from 'react'
import Logo from '../assets/svg/rkc-logo.svg'

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero__inner">
        <div className="hero__item hero__logo">
          <Logo />
        </div>
        <div className="hero__item hero__headline">
          <p>the next generation of PUBLIC RELATIONS</p>
        </div>
      </div>
    </section>
  )
}

export default Hero
