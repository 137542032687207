import React, { useState } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import Slider from 'react-slick'

const DesktopCarousel = ({ slides }) => {
  const [slideIndex, setSlideIndex] = useState(0),
        [transitionState, setTransitionState] = useState(false),
        sliderRef = React.createRef(),
        settings = {
          arrows: false,
          autoplay: true,
          speed: 2000,
          beforeChange: (prev, next) => {
            setTransitionState(true)
            setTimeout(() => {
              setSlideIndex(next)
            }, 1000)
            setTimeout(() => {
              setTransitionState(false)
            }, 1500)
          },
          afterChange: () =>
            setTransitionState(false)
        }

  const goToNext = () => {
    sliderRef.current.slickNext()
  }

  return (
    <section className={`the-carousel ${transitionState ? `transitioning` : ``}`} onClick={goToNext}>
      <div className="the-carousel__inner">
        <Slider {...settings} ref={sliderRef}>
          {slides.map(slide => (
            <div
              className={`slide slide--${slide.items.length}`}
              key={`desktop--${slide.id}`}
            >
              <div className="slide__inner">
                {slide.items.map(image => {
                  if (image.slide_image.localFile === null) {
                    return (
                      <div
                        className="slide__img slide__img--blank"
                        key={`desktop-blank--${Math.floor(
                          Math.random() * 5000
                        )}`}
                      ></div>
                    )
                  } else {
                    return (
                      <div
                        className="slide__img"
                        key={image.slide_image.localFile.id}
                      >
                        <Img
                          fluid={
                            image.slide_image.localFile.childImageSharp.fluid
                          }
                          alt={image.slide_image.alt}
                        />
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          ))}
        </Slider>

        <div className="slide-captions">
          <div className="slide-captions__inner">
            {slides.map((slide, index) => (
              <div
                className={`slide-captions__container ${slideIndex === index ? `slide-captions__container--active` : ``}`}
                key={`captions---${slide.id}`}
              >
                {slide.items.map(image => {
                  if (image.slide_image.localFile === null) {
                    return (
                      <div
                        className="slide-caption slide-caption--blank"
                        key={`desktop-caption--${Math.floor(
                          Math.random() * 5000
                        )}`}
                      >&nbsp;</div>
                    )
                  } else {
                    return (
                      <div
                        className="slide-caption"
                        key={`d-cap--${image.slide_image.localFile.id}`}
                      >
                        <span>
                          {image.slide_image.alt
                            ? image.slide_image.alt
                            : image.slide_image.localFile.childImageSharp.fluid.originalName
                                .split("_")
                                .pop()}
                        </span>
                      </div>
                    )
                  }
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default DesktopCarousel
