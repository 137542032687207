import React, { useState } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import Slider from 'react-slick'

const MobileCarousel = ({ slides }) => {
  const [slideIndex, setSlideIndex] = useState(0),
        [transitionState, setTransitionState] = useState(false),
        sliderRef = React.createRef(),
        realSlides = slides.filter(slide =>
          slide.slide_image.localFile !== null
        ),
        settings = {
          arrows: false,
          autoplay: true,
          speed: 2000,
          touchMove: false,
          swipe: false,
          pauseOnHover: false,
          beforeChange: (prev, next) => {
            setTransitionState(true)
            setTimeout(() => {
              setSlideIndex(next)
            }, 1000)
            setTimeout(() => {
              setTransitionState(false)
            }, 1500)
          },
          afterChange: () =>
            setTransitionState(false)
        }

  return (
    <section className={`the-carousel ${transitionState ? `transitioning` : ``}`}>
      <div className="the-carousel__inner">
        <Slider {...settings} ref={sliderRef}>
          {realSlides.map((slide, index) => {
            return (
              <div className="slide" key={`mobile---${slide.id}--${index}`}>
                <div className="slide__inner">
                  <div className="slide__img">
                    <Img
                      fluid={slide.slide_image.localFile.childImageSharp.fluid}
                      alt={slide.slide_image.alt}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>

        <div className="slide-captions">
          <div className="slide-captions__inner">
            {realSlides.map((slide, index) => {
              return (
                <div
                  className={`slide-captions__container ${slideIndex === index ? `slide-captions__container--active` : ``}`}
                  key={`mobile--${slide.slide_image.localFile.name}`}
                >
                  <div className="slide-caption">
                    <span>
                      {slide.slide_image.alt
                        ? slide.slide_image.alt
                        : slide.slide_image.localFile.childImageSharp.fluid.originalName
                            .split("_")
                            .pop()}
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default MobileCarousel
