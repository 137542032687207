import React, { useState, useEffect } from 'react'
import 'proxy-polyfill'
import { window, document, exists } from 'browser-monads'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Carousel from '../components/carousel/index'
import Services from '../components/services/index'
import { MediaContextProvider } from '../media'

const IndexPage = ({ }) => {
  useEffect(() => {
    // Don't preserve scroll
    window.scroll({ top: 0 })
    // Bonus for Safari
    setTimeout(() => window.scroll({ top: 0 }), 10)
  }, [])

  return (
    <MediaContextProvider>
      <Layout>
        <SEO title="Index" />
        <Hero />
        <Carousel />
        <Services />
      </Layout>
    </MediaContextProvider>
  )
}

export default IndexPage
