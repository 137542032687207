import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import 'css.escape'
import { Media } from '../../media'
import ServiceTitle from './serviceTitle'
import ServiceDescription from './serviceDescription'
import ServiceCard from './serviceCard'

const SERVICES_QUERY = graphql`
  query ServicesQuery {
    allPrismicService {
      nodes {
        id
        data {
          title_color_on_hover
          service_description {
            html
            text
          }
          service_title {
            text
          }
        }
      }
    }
  }
`

const Services = () => {
  const {
    allPrismicService: { nodes: services },
  } = useStaticQuery(SERVICES_QUERY)

  const [dataIndex, setDataIndex] = useState(0)

  const handleTitleOnEnter = index => {
    if (dataIndex !== index) {
      setDataIndex(index)
    }
  }

  const handleTitleonExit = index => {
    setDataIndex(index)
  }

  const longestService = {
    index: 0,
    length: 0,
    text: ``
  };

  services.forEach((service, index) => {
    if(service.data.service_description.text.length > longestService.length) {
      longestService.index = index
      longestService.length = service.data.service_description.text.length
      longestService.text = service.data.service_description.text
    }
  })

  return (
    <>
      <Media lessThan="md">
        <section className="services">
          <div className="services__inner">
            <p className="services__heading">services</p>
            {services.map((service, index) => (
              <ServiceCard
                key={service.id}
                title={service.data.service_title.text}
                description={service.data.service_description.html}
                color={service.data.title_color_on_hover}
              />
            ))}
          </div>
        </section>
      </Media>
      <Media greaterThan="sm">
        <section className="services">
          <style type="text/css">{`
            .services__descriptions::after {
              content: "${CSS.escape(longestService.text)}";
            }
          `}</style>
          <div className="services__inner">
            <p className="services__heading">services</p>
            <div className="services__titles">
              {services.map((service, index) => (
                <ServiceTitle
                  key={service.id}
                  index={index}
                  title={service.data.service_title.text}
                  handleTitleOnEnter={handleTitleOnEnter}
                  handleTitleOnExit={handleTitleonExit}
                  color={service.data.title_color_on_hover}
                  current={dataIndex}
                />
              ))}
            </div>
            <div className="services__descriptions">
              {services.map((service, index) => (
                <ServiceDescription
                  key={service.id}
                  current={dataIndex}
                  description={service.data.service_description.html}
                  index={index}
                />
              ))}
            </div>
          </div>
        </section>
      </Media>
    </>
  )
}

export default Services
