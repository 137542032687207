import React from 'react'

const ServiceDescription = ({ current, index, description }) => {
  let doShow

  current === index ? (doShow = "show") : (doShow = "")

  return (
    <span
      className={doShow}
      dangerouslySetInnerHTML={{ __html: description }}
    ></span>
  )
}

export default ServiceDescription
