import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import 'proxy-polyfill'
import { window, document, exists } from 'browser-monads'
import { Media } from '../../media'
import Helmet from 'react-helmet'
import MobileCarousel from './mobileCarousel'
import DesktopCarousel from './desktopCarousel'

export const CAROUSEL_QUERY = graphql`
  query carouselQuery {
    allPrismicCarousel {
      edges {
        node {
          data {
            body {
              slice_type
              id
              items {
                slide_image {
                  alt
                  dimensions {
                    width
                    height
                  }
                  localFile {
                    id
                    name
                    childImageSharp {
                      fluid(
                        maxWidth: 3200
                        base64Width: 1
                        srcSetBreakpoints: [400, 600, 960, 1280, 1920, 2400]
                      ) {
                        originalName
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const flatten = arr => {
  return arr.reduce(function(flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    )
  }, [])
}

const Carousel = () => {
  const { allPrismicCarousel } = useStaticQuery(CAROUSEL_QUERY)
  const slides = allPrismicCarousel.edges[0].node.data.body
  const mobileSlides = flatten(slides.map(slide => slide.items))
  const domain = `rkcommunications.netlify.com`

  return (
    <>
      <Helmet
        meta={
          flatten(
            mobileSlides.filter(slide =>
              slide.slide_image.localFile !== null
            ).slice(0,3).map(slide => {
              const alt = slide.slide_image.alt,
                    dimensions = slide.slide_image.dimensions,
                    image = slide.slide_image.localFile.childImageSharp.fluid

              return [
                { itemprop: `image`, content: `https://${domain}${slide.slide_image.localFile.childImageSharp.fluid.src}` },
                { name: 'twitter:card', content: `summary_large_image` },
                { name: 'twitter:image', content: `http://${domain}${image.src}` },
                { name: 'twitter:image:alt', content: alt },
                { name: 'og:image', content: `http://${domain}${image.src}` },
                { name: 'og:image:secure_url', content: `https://${domain}${image.src}` },
                { name: 'og:image:width', content: dimensions.width },
                { name: 'og:image:height', content: dimensions.height },
                { name: 'og:image:alt', content: alt },
              ]
            })
          )
        } />
      <Media lessThan="md">
        <MobileCarousel slides={mobileSlides} />
      </Media>
      <Media greaterThan="sm">
        <DesktopCarousel slides={slides} />
      </Media>
    </>
  )
}

export default Carousel
